import { LitElement, html, property } from '@polymer/lit-element';
import { TraceMetadata } from './types'
import * as firebase from 'firebase';

class TraceList extends LitElement {

  @property()
  active;

  @property({ type: Boolean })
  loading = true;

  @property()
  patientid: string;

  @property()
  traceMetadata: TraceMetadata[] = [];

  patientCollection: firebase.firestore.CollectionReference;

  constructor() {
    super();
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        let db = firebase.firestore();
        let userDoc = db.collection("users").doc(user.uid);
        userDoc.set({})
          .then(_ => {
            this.patientCollection = userDoc.collection("patients");
            this._getTraces();
          });
      } else {
        this.patientCollection = undefined;
      }
    });
  }

  _getTraces() {
    if (this.patientid === undefined || this.patientCollection === undefined) {
      return;
    }
    let that = this;
    this.loading = true;
    this.patientCollection.doc(this.patientid)
      .collection("traceMetadata")
      .get()
      .then(querySnapshot => {
        that.traceMetadata = querySnapshot.docs.map(d => d.data()) as TraceMetadata[];
        that.loading = false;
        console.log("Loaded trace metadata", that.traceMetadata);
      });
  }

  _addTrace() {
    if (this.patientid === undefined || this.patientCollection === undefined) {
      return;
    }
    const newID = this.traceMetadata.length + 1;
    this.patientCollection.doc(this.patientid)
      .collection("traceMetadata")
      .doc(newID.toString())
      .set({
        time: firebase.firestore.Timestamp.now(),
      } as TraceMetadata).then(_ => {
        console.log("Added trace metadata", newID);
        let url = this._getRecorderURL(parseInt(this.patientid), newID)
        window.history.pushState({}, "Tremulo", url);
        window.dispatchEvent(new CustomEvent('location-changed'));
        this._getTraces();
      });
  }

  _getRecorderURL(patientid: number, trace: number): string {
    return `/trace-recorder/${patientid}/${trace}`;
  }

  update(changedProperties: Map<string, unknown>) {
    super.update(changedProperties);
    if (changedProperties.has('patientid')) {
      this._getTraces();
    }
  }

  render() {
    return html`
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet">
      <style>
      :host {
        overflow: scroll !important;
      }
      td a { 
        display: block; 
      }
      table {
        font-family: 'Roboto', sans-serif;
      }
      h2 {
        font-family: 'Roboto', sans-serif;
      }
      tr { 
        font-size: 20pt;
      }
      .link-td {
        padding: 0px;
      }
      a {
        padding: 5px;
      }
      tr:hover { 
        background-color: lightgrey;
      }
      </style>
      <h2>Traces for Patient ${this.patientid}</h2>
      <table class="highlight">
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
        </tr>
        </thead>
        ${this.loading ? 
           html`<div class="progress">
              <div class="indeterminate"></div>
            </div>`
          : this.traceMetadata.map((e: TraceMetadata, i) => {
      const date = e.time.toDate().toLocaleDateString();
      const time = e.time.toDate().toLocaleTimeString();
      const href = this._getRecorderURL(parseInt(this.patientid), i + 1);
      return html`
          <tr>
            <td class="link-td">
              <a href="${href}">
              ${date}
            </a></td>
            <td class="link-td">
              <a href="${href}">
              ${time}
            </a></td>
          </tr>`
    })}
      </table>
      <div class="fixed-action-btn" @click=${this._addTrace}>
        <a class="btn-floating btn-large red">
          <i class="large material-icons">add</i>
        </a>
      </div>
      `
  }
}

customElements.define('trace-list', TraceList);