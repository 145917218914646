import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/app-route/app-location.js';
import '@polymer/app-route/app-route.js';
import 'helium-animated-pages/helium-animated-pages.js';
import './patient-list';
import './trace-list';
import './trace-recorder';

class TremuloApp extends PolymerElement {
    page: string;

    constructor() {
        super()
    }

    static get template() {
        return html`
        <style>
            helium-animated-pages {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
            trace-viewer {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
            :host {
                display: flex;
                flex-direction: column;
            }

            .pt-page-moveToLeft {
	        -webkit-animation: moveToLeft .6s ease both;
	        -moz-animation: moveToLeft .6s ease both;
	        animation: moveToLeft .6s ease both;
            }

            .pt-page-moveFromLeft {
            	-webkit-animation: moveFromLeft .6s ease both;
            	-moz-animation: moveFromLeft .6s ease both;
            	animation: moveFromLeft .6s ease both;
            }

            .pt-page-moveToRight {
            	-webkit-animation: moveToRight .6s ease both;
            	-moz-animation: moveToRight .6s ease both;
            	animation: moveToRight .6s ease both;
            }

            .pt-page-moveFromRight {
            	-webkit-animation: moveFromRight .6s ease both;
            	-moz-animation: moveFromRight .6s ease both;
            	animation: moveFromRight .6s ease both;
            }

            .pt-page-fade {
            	-webkit-animation: fade .7s ease both;
            	-moz-animation: fade .7s ease both;
            	animation: fade .7s ease both;
            }


            @-webkit-keyframes moveToLeft {
            	to { -webkit-transform: translateX(-100%); }
            }
            @-moz-keyframes moveToLeft {
            	to { -moz-transform: translateX(-100%); }
            }
            @keyframes moveToLeft {
            	to { transform: translateX(-100%); }
            }

            @-webkit-keyframes moveFromLeft {
            	from { -webkit-transform: translateX(-100%); }
            }
            @-moz-keyframes moveFromLeft {
            	from { -moz-transform: translateX(-100%); }
            }
            @keyframes moveFromLeft {
            	from { transform: translateX(-100%); }
            }

            @-webkit-keyframes moveToRight { 
            	to { -webkit-transform: translateX(100%); }
            }
            @-moz-keyframes moveToRight { 
            	to { -moz-transform: translateX(100%); }
            }
            @keyframes moveToRight { 
            	to { transform: translateX(100%); }
            }

            @-webkit-keyframes moveFromRight {
            	from { -webkit-transform: translateX(100%); }
            }
            @-moz-keyframes moveFromRight {
            	from { -moz-transform: translateX(100%); }
            }
            @keyframes moveFromRight {
            	from { transform: translateX(100%); }
            }

            @-webkit-keyframes fade {
            	to { opacity: 0.3; }
            }
            @-moz-keyframes fade {
            	to { opacity: 0.3; }
            }
            @keyframes fade {
            	to { opacity: 0.3; }
            }

        </style>
        <app-location route="{{route}}"></app-location>

        <app-route route="{{route}}" pattern="/:page" data="{{routeData}}" tail="{{subroute}}"></app-route>
        <app-route route="{{subroute}}" pattern="/:patient" data="{{subRouteData}}" tail="{{subsubroute}}"></app-route>
        <app-route route="{{subsubroute}}" pattern="/:trace" data="{{subSubRouteData}}"></app-route>

        <helium-animated-pages id="pages" role="main" selected="[[page]]" attr-for-selected="name" fallback-selection="patients">
            <patient-list 
                id="patients" 
                name="patients">
            </patient-list>
            <trace-list 
                id="traces" 
                name="traces" 
                patientid="{{subRouteData.patient}}">
            </trace-list>
            <trace-recorder
                id="trace-recorder" 
                name="trace-recorder"
                patientid="{{subRouteData.patient}}"
                traceid="{{subSubRouteData.trace}}">
            </trace-recorder>
        </helium-animated-pages>
        `
    }

    ready() {
        super.ready();
        let pages = this.shadowRoot.querySelector('#pages') as any;
        pages.animationClasses = {
            'traces_trace-recorder': {
                in: 'pt-page-moveFromRight',
                out: 'pt-page-moveToLeft'
            },
            'trace-recorder_traces': {
                in: 'pt-page-moveFromLeft',
                out: 'pt-page-moveToRight'
            },
            '*_traces': {
                in: 'pt-page-moveFromRight',
                out: 'pt-page-moveToLeft'
            },
            'traces_*': {
                in: 'pt-page-moveFromLeft',
                out: 'pt-page-moveToRight'
            },
            '*_patients': {
                in: 'pt-page-moveFromLeft',
                out: 'pt-page-moveToRight'
            },
            'patients_*': {
                in: 'pt-page-moveFromRight',
                out: 'pt-page-moveToLeft'
            },
            default: {
                in: 'pt-page-fadeIn',
                out: 'pt-tpage-fadeOut'
            }
        };
    }

    static get properties() {
        return {
            page: {
                type: String,
                reflectToAttribute: true,
                observer: '_pageChanged'
            },
        }
    }

    static get observers() {
        return [
            '_routePageChanged(routeData.page)'
        ]
    }

    _routePageChanged(page) {
        if (page === undefined || page === '') {
            // set the page so that the view element is loaded
            this.page = 'patients';

            // change the browser location
            window.history.replaceState({}, null, `/${this.page}`);
            window.dispatchEvent(new CustomEvent('location-changed'));
            return;
        }

        this.page = page
    }

    _pageChanged(page, oldPage) {
    }

}

customElements.define('tremulo-app', TremuloApp);