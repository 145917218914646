import * as varint from 'signed-varint';
import { TracePointsEncoded, TracePointsDecoded } from './types'
import { firestore } from 'firebase';

function deltaVarintEncode(arr: number[]): firestore.Blob {
    let prev = 0;
    let buffer = [];
    let offset = 0;
    arr.forEach(a => {
        varint.encode(a - prev, buffer, offset);
        prev = a;
        offset += varint.encode.bytes
    })
    return firestore.Blob.fromUint8Array(new Uint8Array(buffer));
}

function deltaVarintDecode(blob: firestore.Blob) {
    let buffer = blob.toUint8Array();
    let arr = [];
    let prev = 0;
    for (let offset = 0; offset < buffer.length; offset += varint.decode.bytes) {
        let a = prev + varint.decode(buffer, offset);
        arr.push(a);
        prev = a;
    }
    return arr;
}

export function encodeTrace(trace: TracePointsDecoded): TracePointsEncoded {
    return {
        height: trace.height,
        width: trace.width,
        locked: trace.locked,
        xs: deltaVarintEncode(trace.xs),
        ys: deltaVarintEncode(trace.ys),
        ts: deltaVarintEncode(trace.ts),
    }
}

export function decodeTrace(trace: TracePointsEncoded): TracePointsDecoded {
    return {
        height: trace.height,
        width: trace.width,
        locked: trace.locked,
        xs: deltaVarintDecode(trace.xs),
        ys: deltaVarintDecode(trace.ys),
        ts: deltaVarintDecode(trace.ts),
    }
}
