import { LitElement, html, property } from '@polymer/lit-element';
import { Patient } from './types'
import * as firebase from 'firebase';

class PatientList extends LitElement {
  @property({ type: Boolean })
  loading = true;

  @property()
  patients: Patient[] = [];

  patientsCollection: firebase.firestore.CollectionReference;

  constructor() {
    super();
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        let db = firebase.firestore();
        let userDoc = db.collection("users").doc(user.uid);
        userDoc.set({})
          .then(_ => {
            this.patientsCollection = userDoc.collection("patients");
            this.getPatients();
          })
      } else {
        this.patientsCollection = undefined;
      }
    });
  }

  getPatients() {
    if (this.patientsCollection !== undefined) {
      this.loading = true;
      this.patientsCollection.get().then(querySnapshot => {
        this.loading = false;
        this.patients = querySnapshot.docs.map(d => d.data()) as Patient[];
      })
    }
  }

  addPatient() {
    let newID = this.patients.length + 1;
    this.patientsCollection.doc(newID.toString()).set({
      enrollmentTime: firebase.firestore.Timestamp.now(),
    } as Patient).then(_ => this.getPatients());
  }

  render() {
    return html`
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
      <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet">
      <style>
      td a { 
        display: block; 
      }
      table {
        font-family: 'Roboto', sans-serif;
      }
      h2 {
        font-family: 'Roboto', sans-serif;
      }
      tr { 
        font-size: 20pt;
      }
      .link-td {
        padding: 0px;
      }
      a {
        padding: 5px;
      }
      tr:hover { 
        background-color: lightgrey;
      }
      </style>
      <h2>Patients</h2>
      <table class="highlight">
      <thead>
        <tr>
          <th>ID</th>
          <th>Enrollment Date</th>
        </tr>
        </thead>
        ${this.loading ?
        html`<div class="progress">
              <div class="indeterminate"></div>
            </div>`
        : this.patients.map((patient: Patient, i) => {
          return html`
          <tr>
            <td class="link-td"><a href="/traces/${i + 1}">${i + 1}</a></td>
            <td class="link-td"><a href="/traces/${i + 1}">${patient.enrollmentTime.toDate().toISOString().slice(0, 10)}</a></td>
          </tr>`
        })}
      </table>
      <div class="fixed-action-btn" @click=${this.addPatient}>
        <a class="btn-floating btn-large red">
          <i class="large material-icons">add</i>
        </a>
      </div>
      `;
  }
}

customElements.define('patient-list', PatientList);