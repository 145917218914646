import { LitElement, html, property } from '@polymer/lit-element';
import { TracePointsEncoded, TracePointsDecoded } from './types'
import { encodeTrace, decodeTrace } from './points-encoder';
import * as firebase from 'firebase';

const COLLECTION_NAME = "tracePoints";

class TraceRecorder extends LitElement {
    @property()
    active;

    @property()
    saveState = '';

    @property({ type: Boolean })
    loading = true;

    @property({ type: Number })
    patientid: string;

    @property({ type: Number })
    traceid: string;

    dirty: boolean = false;

    drawing: boolean = false;

    patientCollection: firebase.firestore.CollectionReference;

    trace: TracePointsDecoded;

    context: CanvasRenderingContext2D;

    constructor() {
        super();

        // Display prompt if the user closes the page before the trace is saved.
        window.onbeforeunload = _ => {
            if (this.dirty) {
                this._saveNow();
            }
            return this.dirty || null;
        }

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                let db = firebase.firestore();
                let userDoc = db.collection("users").doc(user.uid);
                userDoc.set({})
                    .then(_ => {
                        this.patientCollection = userDoc.collection("patients");
                        window.setInterval(this._getScheduleSave(), 1000);
                        this._getTrace();
                    });
            } else {
                this.patientCollection = undefined;
                window.clearInterval();
            }
        });
    }

    _addPoint(x: number, y: number) {
        if (this.trace === undefined) {
            console.error("no trace loaded");
            return;
        }
        if (this.trace.locked) {
            return;
        }
        this.trace.xs.push(x);
        this.trace.ys.push(y);
        this.trace.ts.push(Date.now());
        this.dirty = true;
    }

    _getHandleStart() {
        let that = this;
        return (evt: MouseEvent) => {
            if (that.trace.locked) {
                return;
            }
            let [x, y] = [evt.x, evt.y];
            that._addPoint(x, y);
            that.context.moveTo(x, y);
            that.drawing = true;
        }
    }

    _getHandleEnd() {
        let that = this;
        return (evt: MouseEvent) => {
            if (that.trace.locked) {
                return;
            }
            that.context.stroke();
            that.drawing = false;
        }
    }

    _getHandleMove() {
        let that = this;
        return (evt: MouseEvent) => {
            evt.preventDefault();
            if (!that.drawing || that.trace.locked) {
                return;
            }
            let [x, y] = [evt.x, evt.y];
            x = Math.round(x);
            y = Math.round(y);
            let prevX = this.trace.xs[this.trace.xs.length - 1];
            let prevY = this.trace.ys[this.trace.ys.length - 1];
            this._addPoint(x, y);
            window.requestAnimationFrame(_ => {
                this.context.beginPath();
                this.context.moveTo(prevX, prevY);
                this.context.lineTo(x, y);
                this.context.stroke();
            })
        }
    }

    _getHandleTouchStart() {
        return (evt: TouchEvent) => {
            if (this.trace.locked) {
                return;
            }
            let touch = evt.touches[0];
            let [x, y] = [touch.clientX, touch.clientY];
            x = Math.round(x);
            y = Math.round(y);
            this._addPoint(x, y);
            this.context.moveTo(x, y);
            this.drawing = true;
            evt.preventDefault();
        }
    }

    _getHandleTouchEnd() {
        return (evt: TouchEvent) => {
            if (this.trace.locked) {
                return;
            }
            var mouseEvent = new MouseEvent("mouseup", {});
            this.context.canvas.dispatchEvent(mouseEvent);
        }
    }

    _getHandleTouchMove() {
        return (evt: TouchEvent) => {
            if (!this.drawing || this.trace.locked) {
                return;
            }
            let touch = evt.touches[0];
            let [x, y] = [touch.clientX, touch.clientY];
            x = Math.round(x);
            y = Math.round(y);
            let prevX = this.trace.xs[this.trace.xs.length - 1];
            let prevY = this.trace.ys[this.trace.ys.length - 1];
            this._addPoint(x, y);
            window.requestAnimationFrame(_ => {
                this.context.beginPath();
                this.context.moveTo(prevX, prevY);
                this.context.lineTo(x, y);
                this.context.stroke();
            })
            evt.preventDefault();
        }
    }

    _saveNow() {
        this.dirty = false;
        let traceEncoded = encodeTrace(this.trace);
        console.log("Saving trace with", this.trace.ts.length, "points");
        let t = (this.trace.ts[this.trace.ts.length - 1] - this.trace.ts[0]) / 1000;
        console.log(this.trace.xs.length / t, "points per second");
        this.patientCollection
            .doc(this.patientid)
            .collection(COLLECTION_NAME)
            .doc(this.traceid)
            .set(traceEncoded)
            .then(_ => {
                this.saveState = 'Saved';
                window.setTimeout(_ => this.saveState = '', 3000);
                console.log("Successfully saved")
            })
            .catch(reason => {
                console.error(reason);
                this.dirty = true;
            });
    }

    _getScheduleSave() {
        return _ => {
            if (this.drawing || !this.dirty) {
                return;
            }
            window.setTimeout(_ => {
                if (this.drawing || !this.dirty) {
                    return;
                }
                this._saveNow();
            }, 1000);
        }
    }

    _drawTrace() {
        this.context.beginPath();
        for (let i = 0; i < this.trace.xs.length; i++) {
            let [x, y] = [this.trace.xs[i], this.trace.ys[i]];
            if (i === 0) {
                this.context.moveTo(x, y);
                continue;
            }
            this.context.lineTo(x, y);
        }
        this.context.stroke();
    }

    _getTrace() {
        if (this.patientid === undefined
            || this.traceid === undefined
            || this.patientCollection === undefined) {
            return;
        }
        this.loading = true;
        let that = this;
        this.patientCollection
            .doc(this.patientid)
            .collection(COLLECTION_NAME)
            .doc(this.traceid)
            .get()
            .then((doc) => {
                that.loading = false;
                let data = doc.data();
                if (!!data) {
                    that.trace = decodeTrace(data as TracePointsEncoded);
                    console.log("Loaded trace");
                } else {
                    this._deleteAndClear();
                    console.log("Set empty trace");
                }
            })
    }

    _clearAndDrawSpiral() {
        if (!this.context) {
            return;
        }
        this.context.canvas.height = this.trace.height;
        this.context.canvas.width = this.trace.width;
        this.context.fillStyle = "white";
        this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height);
        this.context.beginPath();
        this.context.strokeStyle = "red";
        this.context.lineWidth = 3;
        const canvas = this.context.canvas;
        this.context.moveTo(canvas.width / 2, canvas.height / 2);
        for (let i = 0; ; i++) {
            let angle = 0.1 * i;
            const k = 10;
            let x = canvas.width / 2 + k * (1 + angle) * Math.cos(angle);
            let y = canvas.height / 2 + k * (1 + angle) * Math.sin(angle);
            if (x < 0 || x >= canvas.width) {
                break;
            }
            if (y < 0 || y >= canvas.height) {
                break;
            }
            this.context.lineTo(x, y);
        }
        this.context.stroke();
        this.context.strokeStyle = "black";
        this.context.lineWidth = 2;
        this.context.beginPath();
    }

    _deleteAndClear() {
        this.trace = {
            // Use the current viewport size
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
            locked: false,
            ts: [],
            xs: [],
            ys: [],
        }
        this.dirty = true;
        this._clearAndDrawSpiral();
        this.requestUpdate();
    }

    _lock() {
        this.trace.locked = !this.trace.locked;
        this.dirty = true;
        this.requestUpdate();
    }

    update(changedProperties) {
        super.update(changedProperties);
        if (this.dirty) {
            this._saveNow();
        }
        if (changedProperties.has('patientid') ||
            changedProperties.has('traceid')) {
            this._getTrace();
        }
    }

    updated() {
        if (this.context === undefined) {
            const canvas = (this.shadowRoot.querySelector("#canvas") as HTMLCanvasElement)
            if (canvas === null) {
                return;
            }
            canvas.width = this.trace.width || document.documentElement.clientWidth;
            canvas.height = this.trace.height || document.documentElement.clientHeight;
            canvas.addEventListener("mousedown", this._getHandleStart(), false);
            canvas.addEventListener("mousemove", this._getHandleMove(), false);
            canvas.addEventListener("mouseup", this._getHandleEnd(), false);
            canvas.addEventListener("touchstart", this._getHandleTouchStart(), false);
            canvas.addEventListener("touchmove", this._getHandleTouchMove(), false);
            canvas.addEventListener("touchend", this._getHandleTouchEnd(), false);
            this.context = canvas.getContext("2d", { alpha: false });
            // TODO: handle resize
        }
        this._clearAndDrawSpiral();
        this._drawTrace();
    }

    render() {
        return html`
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css">
            <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
            <style>
                canvas {
                    position: absolute;
                }
                #lock-button {
                    right: unset;
                    left: 23px;
                }
                #save-state {
                    position: absolute;
                    display: block;
                    top: -100px;
                    left: -100px;
                    margin: 10px;
                    padding: 10px;
                    z-index: 1;
                    text-align: left;
                    font-family: 'Roboto', sans-serif;
                    background: #e6f7ff;
                    border-radius: 15px;
                    font-size: 32pt;
	                animation: moveUpAndDown 3s ease both;
                }
            @keyframes moveUpAndDown {
            	20% { top: 0px; left: 0px}
            	80% { top: 0px; left: 0px}
                100% { top: -100px; left: -100px}
            }
            </style>
        ${this.loading ?
                html`<div class="progress">
              <div class="indeterminate"></div>
            </div>`
                : html`
            <canvas id="canvas"></canvas>`}

            ${this.saveState === '' ? '' :
                html`<div id="save-state"
                 aria-live="assertive"
                 aria-atomic="true"
                 aria-hidden="true">
                 ${this.saveState}
            </div>`
            }

            <div id="lock-button" class="fixed-action-btn" @click=${this._lock}>
                <a class="btn-floating btn-large red">
                    <i class="large material-icons">${this.trace && this.trace.locked ? 'lock' : 'lock_open'}</i>
                </a>
            </div>
            
            <div class="fixed-action-btn" @click=${this._deleteAndClear}>
                <a class="btn-floating btn-large red">
                    <i class="large material-icons">refresh</i>
                </a>
            </div>
        `
    }

}

customElements.define('trace-recorder', TraceRecorder);